import React from "react"
import { Link } from "gatsby"
import Wrapper from "../mainComponents/Wrapper"
import Layout from "../mainComponents/Layout"
import dribbball from "../assets/logos_dribbble-icon.svg"
import dribText from "../assets/text_dribbble.svg"
import Footer from "../mainComponents/Footer"
import FooterMail from "../components/FooterMail"
import "../style/portfolio.scss"
import Slider from "react-slick"
import animals from "../assets/blog slick/ANIMALS.png"
import art from "../assets/blog slick/art room.png"
import burn from "../assets/blog slick/burners.png"
import cyber from "../assets/blog slick/Cyberpunk_cycles6.png"
import fashion from "../assets/blog slick/Fashion.png"
import movadesk from "../assets/blog slick/movadesk.png"
import estate from "../assets/blog slick/Real Estate.png"
import smart from "../assets/blog slick/Smart home.png"
import itmarket from "../assets/blog slick/Ukrainian IT Market_.png"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import useResize from "../constants/useResize"
import SEO from "../mainComponents/seo"
const Portfolio = () => {
  const {innerWidth} = useResize()

  const settings = {
    autoplay: true,
    autoplaySpeed: 1500,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: innerWidth >= 1380 ? 4 : innerWidth >= 900 ? 3 : innerWidth >= 500 ? 2 : 1,
    slidesToScroll: 1,
  }
  return (
    <Wrapper>
      <Layout>
        <SEO title="Portfolio"
             description={"Just a small number of projects from our happy customers!"} />
          <section className="portfolio" id="portfolio">
            <div className="container">
              <h2 className="portfolio-title">Your idea can be here</h2>
              <h6 className="portfolio__subtitle">Latest cases</h6>
              <p className="portfolio__text">Fostering creativity, we create never-seen-before solutions for each and every client, exceeding industry standards and customer expectations.</p>

              <div className="portfolio__block">
                <Link to="/cases/astrolife/" className="portfolio-item">
                  <h4 className="portfolio-item__title">Astrolife</h4>
                  <p className="portfolio-item__tags">UI/UX, Development</p>
                </Link>

                <Link to="/cases/pyoor/" className="portfolio-item">
                  <h4 className="portfolio-item__title">Pyoor</h4>
                  <p className="portfolio-item__tags">Web-development, UI/UX</p>
                </Link>

                <Link to="https://www.movadex.com/cases/tastebuds/" className="portfolio-item">
                  <h4 className="portfolio-item__title">TasteBuds</h4>
                  <p className="portfolio-item__tags">UI/UX, iOS, Android</p>
                </Link>
                <Link className="portfolio-item">
                  <h4 className="portfolio-item__title">Free Vestia</h4>
                  <p className="portfolio-item__tags">Web-development, UI/UX, Branding</p>
                </Link>

                <Link to="https://apexcapitalrealty.com" className="portfolio-item">
                  <h4 className="portfolio-item__title">Apex</h4>
                  <p className="portfolio-item__tags">Android, iOS, iPadOS, UI/UX</p>
                </Link>


                <Link to="/cases/flight-simulator/" className="portfolio-item">
                  <h4 className="portfolio-item__title">Flight Simulator</h4>
                  <p className="portfolio-item__tags">iOS (iPadOS), UI/UX, Branding</p>
                </Link>


                <Link to="/cases/turtlequeue/" className="portfolio-item">
                  <h4 className="portfolio-item__title">Turtlequeue</h4>
                  <p className="portfolio-item__tags">Web-development, UI/UX</p>
                </Link>


                <Link to="/cases/startify/" className="portfolio-item">
                  <h4 className="portfolio-item__title">Startify</h4>
                  <p className="portfolio-item__tags">Web-development, Product design, Marketing</p>
                </Link>


                <Link to="/cases/kyiv-metro-app/" className="portfolio-item">
                  <h4 className="portfolio-item__title">Kyiv Metro App</h4>
                  <p className="portfolio-item__tags">UI/UX, iOS, Android</p>
                </Link>


                <Link to="/cases/uprice/" className="portfolio-item">
                  <h4 className="portfolio-item__title">Uprice</h4>
                  <p className="portfolio-item__tags">Android, iOS, Product design, Branding</p>
                </Link>


                <Link to="/cases/znaj-ua/" className="portfolio-item">
                  <h4 className="portfolio-item__title">ZNAJ.UA</h4>
                  <p className="portfolio-item__tags">Android, iOS, iPadOS, UI/UX</p>
                </Link>

                <Link to="https://fairplay-management.de" className="portfolio-item">
                  <h4 className="portfolio-item__title">Fairplay</h4>
                  <p className="portfolio-item__tags">Web-development, UI/UX</p>
                </Link>

                <Link to="/cases/plan-b/" className="portfolio-item">
                  <h4 className="portfolio-item__title">PlanB</h4>
                  <p className="portfolio-item__tags">UI/UX, Branding</p>
                </Link>

                <Link to="/cases/legends-pro/" className="portfolio-item">
                  <h4 className="portfolio-item__title">LegendsPro</h4>
                  <p className="portfolio-item__tags">UI/UX, Development</p>
                </Link>

                <Link to="/cases/klt-health/" className="portfolio-item">
                  <h4 className="portfolio-item__title">KLT Health</h4>
                  <p className="portfolio-item__tags">Web-development, UI/UX</p>
                </Link>


                {/*<Link to="/cases/medicus-ug/" className="portfolio-item">*/}
                {/*  <h4 className="portfolio-item__title">Medicus Ug</h4>*/}
                {/*  <p className="portfolio-item__tags">UI/UX, Branding</p>*/}
                {/*</Link>*/}


                <Link to="/survey/" className="portfolio-item">
                  <h4 className="portfolio-item__title_add">Add my project here</h4>
                </Link>

              </div>
              <div className="dribbble-section">
                <div className="blog-slider-section">
                  <div  onClick={(() => {
                    window.open("https://dribbble.com/movadex", "_blank")
                  })} className="logo-dribbble">
                    <img src={dribbball} alt="Ball"/>
                    <img src={dribText} alt="Text Logo"/>
                  </div>
                  <div onClick={(() => {
                    window.open("https://dribbble.com/movadex", "_blank")
                  })} className="trending-blog">
                    <span>Be in touch with trending web design</span>
                    <div className="portfolio-slider">
                      <Slider {...settings}>
                        <div>
                          <img src={animals} alt="Animals" />
                        </div>
                        <div>
                          <img src={art} alt="Art" />
                        </div>
                        <div>
                          <img src={burn} alt="Burn" />
                        </div>
                        <div>
                          <img src={cyber} alt="Cyber" />
                        </div>
                        <div>
                          <img src={fashion} alt="Fashion" />
                        </div>
                        <div>
                          <img src={movadesk} alt="Movadesk" />
                        </div>
                        <div>
                          <img src={estate} alt="Estate" />
                        </div>
                        <div>
                          <img src={smart} alt="Smart" />
                        </div>
                        <div>
                          <img src={itmarket} alt="It Market" />
                        </div>
                      </Slider>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </section>
      </Layout>

      <Footer>
        <FooterMail />
      </Footer>
    </Wrapper>
  )
}

export default Portfolio
