import {useState, useEffect} from 'react';


export default function useResize() {
  const isBrowser = typeof window !== 'undefined'
  const [innerWidth, setInnerWidth] = useState(isBrowser ? window.innerWidth : 0)

  useEffect(() => {
    if (typeof window === 'undefined') return;

    const handleResize = () => setInnerWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize)
    };
  },[]);

  return { innerWidth }
}
